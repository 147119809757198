.h-p10 {
  padding: 10px;
}

.h-p20 {
  padding: 20px;
}

.h-pr10 {
  padding-right: 10px;
}

.h-pr20 {
  padding-right: 20px;
}

.h-pt10 {
  padding-top: 10px;
}

.h-pt20 {
  padding-top: 20px;
}

.h-plr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.h-plr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.h-plr40 {
  padding-left: 40px;
  padding-right: 40px;
}

.h-plr70 {
  padding-left: 70px;
  padding-right: 70px;
}

.hb-cursor {
  cursor: pointer;
}

.h-fl {
  float: left;
}

.h-fr {
  float: right;
}

.h-m0 {
  margin: 0px;
}

.h-p0 {
  padding: 0px;
}

.h-mb10 {
  margin-bottom: 10px;
}

.h-mb15 {
  margin-bottom: 15px;
}

.h-mt20 {
  margin-top: 20px;
}

.h-mt40 {
  margin-top: 40px;
}

.h-mb5 {
  margin-bottom: 5px;
  display: block;
}

.h-mr10 {
  margin-right: 10px;
}

.h-btn {
  width: 100px;
  height: 40px;
  border-radius: 4px;
}

.h-save,
.h-saven:hover,
.h-saven:focus {
  background: #c57452 !important;
  color: #fff !important;
  border: none;
}

.h-cancel {
  border-color: #c57452;
  color: #c57452;
}

.topnav {
  overflow: hidden;
}

.topnav a {
  float: left;
  color: #000000d9;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 14px;
}

.topnav a:hover {
  color: #1890ff;
}

.topnav a.active {
  color: #1890ff;
}

.topnav .logout-btn {
  margin-top: 10px;
  margin-left: 30px;
}

.ant-page-header-heading {
  border-bottom: 1px solid gray;
}

.z-m-auto {
  margin: auto;
}

.z-text-center {
  /* text-align: center; */
}

.z-ta-center {
  text-align: center;
}

.success-row {
  background: #81c981;
}

.success-row .ant-table-column-sort {
  background: #81c981;
}

.failed-row {
  background: #eb9797;
}

.failed-row .ant-table-column-sort {
  background: #eb9797;
}

.pending-row {
  background: #e3e389;
}

.pending-row .ant-table-column-sort {
  background: #e3e389;
}

.complete-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.complete-table td,
.complete-table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.complete-table tr:nth-child(even) {
  background-color: #dddddd;
}

mark,
.mark {
  background-color: yellow;
}

@media only screen and (max-width: 600px) {
  .h-p0-sm {
    padding: 0px;
  }

  .h-pr20-sm {
    padding: 0px;
    padding-right: 20px;
  }

  .h-plr40-sm {
    padding-left: 10px;
    padding-right: 15px;
  }

  .topnav a {
    padding: 0px;
    padding-right: 14px;
  }

  .topnav .logout-btn {
    margin-top: 0px;
  }

  .topnav .logout-btn .label {
    display: none;
  }

  .bike-price-table .ant-table-thead>tr>th,
  .ant-table-tbody>tr>td,
  .ant-table tfoot>tr>th,
  .ant-table tfoot>tr>td {
    padding: 8px 10px;
  }

  .order-table .ant-table-thead>tr>th,
  .order-table .ant-table-tbody>tr>td,
  .ant-table tfoot>tr>th,
  .ant-table tfoot>tr>td {
    padding: 8px 10px;
  }
}

@media only screen and (max-width: 376px) {
  .topnav .logout-btn {
    margin-left: 0px;
  }
}

@media print { 
  .ant-modal-wrap {
    overflow: hidden;
    margin: 0;
  }

  .ant-modal {
    width: 100%;
    height: 100%;
    top: 0px;
  }

  .ant-modal-content {
    width: auto;
  }

  .ant-modal-body { 
    overflow: hidden;
  }

  .ant-modal-footer {
    display: none;
  }

  /* Hide URL footer */
  @page {
    size: 'A4';
    margin: 0;
  } 
} 