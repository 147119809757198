* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f1f5f8 !important;
  font-family: "Open Sans", sans-serif !important;
}

iframe {
  pointer-events: none;
}