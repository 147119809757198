.ant-pagination {
  background: #e6ebee;
  padding: 5px;
  border-radius: 15px;
}

.ant-pagination-item {
  background: transparent;
  border: none;
}

.ant-pagination-item-active {
  background: #2b50aa;
  color: #fff;
  border: none;
}

.ant-pagination-item-active a {
  color: #fff;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border: none;
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: #2b50aa;
}

.site-page-header {
  padding: 0px 10px;
}

.ant-steps-item-title {
  font-size: 14px;
  line-height: 38px;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #1890ff;
  font-weight: bold;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title { 
  font-weight: bold;
  text-decoration: underline;
}

.ant-steps-item-title::after{
    top: 19px; 
}

/* .ant-picker-time-panel-column:not(:first-child) {
  overflow: hidden !important;
} */
.ant-picker-datetime-panel .ant-picker-time-panel-column:not(:first-child)::after{
  display: none !important;
}
 

.filter-btns button{
  margin-right:10px ;
  margin-bottom: 10px;
}

.filter-select{
  margin-bottom: 10px;
}

.login-form{
  margin: auto;
    margin-top: 10%;
}

.overide-disable[disabled] {
  color: rgb(6 6 6);
  background-color: #ffffff;
  border:none;
}